$font: 'Museo Sans Cyrl', sans-serif;
$url: '../img/';
$urlIco: '../img/icon/';
$urlFont: '../fonts/';

@font-face {
  font-family: 'Bebas Neue';
  src: local('BebasNeueRegular'), local('BebasNeueRegular'), url($urlFont + 'BebasNeueRegular.woff') format('woff'), url($urlFont + 'BebasNeueRegular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: local('BebasNeueBold'), local('BebasNeueBold'), url($urlFont + 'BebasNeueBold.woff') format('woff'), url($urlFont + 'BebasNeueBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
body{
  font-family: $font;
  color: $color-dark;
  font-weight: 400;
  min-width: 320px;
  font-size: 13px;
  height: 100%;
  margin: 0;
  background-color: $color-light;
  line-height: 1.2em;
  &.active-menu{
    overflow: hidden;
  }

  @media (max-width: 600px){
    padding-bottom: 50px;
  }
}
*{
  outline: none;
  &:focus{
    outline: none !important;
  }
}
a{
  color: $color-dark;
  &:hover{
    text-decoration: none;
  }
}
img{
  max-width: 100%;
}

.contain {
  padding: 8px 15px;
  display: flex;

  @media (max-width: 600px){
    flex-wrap: wrap;
  }
}

#content{
  margin-top: 92px;

  @media (max-width: 600px){
    margin-top: 0;
    width: 100%;
  }
}

.content-mask {
  overflow: hidden;
}

.row-flex{
  display: flex;

  @media (max-width: 600px){
    flex-wrap: wrap;
  }
}

.content-960{
  width: 960px;
  //flex: 0 0 960px;

  @media (max-width: 600px){
    width: 100%;
    //flex: 1 1 100%;
  }
}

.content-745{
  width: 745px;
  //flex: 0 0 745px;

  @media (max-width: 600px){
    width: 100%;
    //flex: 1 1 100%;
  }
}
@media (min-width: 601px){
  .hide-sm {
    display: none;
  }
}

@media (max-width: 600px){
  .hidden-xs {
    display: none;
  }

  #min-content{
    width: 100% !important;
  }
}

.text-right{
  text-align: right;
}

#nprogress .bar{
  background: $color-black !important;
}

#nprogress .peg{
  box-shadow: 0 0 10px $color-black, 0 0 5px $color-black !important;
}

#nprogress .spinner-icon{
  border-top-color: $color-black !important;
  border-left-color: $color-black !important;
}