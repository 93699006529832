@import "colors";
@import "common";

@mixin sideBar {
  position: relative;
  height: 532px;
  margin-top: 5px;
  padding: 20px;
  background: $color-white;
  box-sizing: border-box;
}

@mixin sideBarLink {
  font-family: 'Bebas Neue', Arial, sans-serif;
  font-size: 2.32em;
  color: $color-black;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.2;
  transition: color .3s;

  &:hover {
    color: lighten($color-dark, 10%);
    text-decoration: none;
  }
}

.page-header{
  font-family: 'Bebas Neue', Arial, sans-serif;
  font-size: 2.32em;
  margin: 0.5em 0;
  color: $color-black;
  line-height: 1em;
  font-weight: 600;
}
/* Header */
.header{
  flex: 0 0 197px;
  &__logo{
    display: block;
    height: 92px;

    img{
      display: block;
      width: 170px;
      margin: 0 auto;
    }
  }

  &__lang{
    position: fixed;
    top: 80px;
    right: 20px;
    font-family: 'Bebas Neue', Arial, sans-serif;
    font-weight: 600;
    font-size: 1.4em;

    a{
      text-decoration: none;
      color: $color-black;
      transition: color .3s;

      &:hover, &.active{
        color: $color-dark;
      }

      &.active{
        cursor: default;
      }
    }
  }

  &__nav{
    @include sideBar;

    z-index: 3;
  }

  .menu-btn{
    display: none;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    font-family: 'Bebas Neue', Arial, sans-serif;
    font-size: 30px;
    font-weight: 600;
    color: $color-black;
    text-decoration: none;

    &.open{
      color: $color-dark;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    flex: 1 1 100%;

    &__logo{
      width: 130px;
      margin: 0 auto;
    }

    &__lang{
      display: none;
    }

    &__nav{
      height: auto;
      width: 100%;
      position: relative;
      padding: 0;
      padding-top: 20px;
      background-color: transparent;
    }

    .menu-btn{
      display: block;
    }
  }
}

.nav{
  ul{
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }
  &__item{
    a{
      @include sideBarLink;

      transition: .3s;
    }

    &.active{
      @media (max-width: 600px){
        a{
          color: $color-dark
        }
      }
    }
  }

  &.opened{
    .nav__item{
      a{
        color: $color-dark;

        &:hover {
          color: $color-black;
        }
      }

      &.active{
        a{
          color: $color-black;
        }
      }
    }
  }

  @media (max-width: 600px){
    display: none;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 40px;

    &__item {
      margin-bottom: 0.15em;
      background-color: $color-white;

      a{
        display: inline-block;
        width: 100%;
        padding: 0.5em 0;
        text-align: center;
      }
    }
  }
}

.sub-cat{
  display: none;

  .nav__item{
    background-color: lighten($color-light, 6%);
  }

  @media (min-width: 601px) {
    display: none !important;
  }
}

.social{
  position: absolute;
  bottom: 18px;
  height: 23px;
  font-family: 'Bebas Neue', Arial, sans-serif;
  font-size: 30px;
  color: $color-black;
  &__item{
    &:not(:first-child) {
      &:before {
        content: '';
        display: inline-block;
        width: 2px;
        height: 18px;
        margin: 0 5px;
        background-color: $color-black;
        vertical-align: middle;
      }
    }
  }

  a{
    transition: opacity .3s;
    &:hover{
      opacity: .5;
    }
  }

  @media (max-width: 600px){
    display: none;
  }
}

/* Work */
.block-mask{
  position: relative;
  z-index: 2;
  background-color: $color-light;
}
.slide-content{
  @include sideBar;

  margin-left: 14px;
  padding: 14px;
  left: -100%;
  transition: left .6s;
  z-index: 1;

  &.no-bg {
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  @media (max-width: 600px){
    height: auto;
    margin-left: 0;
  }
}

.slide-content-stay{
  .left-menu{
    left: 0 !important;
  }
}

.left-menu{
  width: 197px;
  padding: 20px;
  z-index: 2;

  &__nav{
    ul{
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    &__item{

      a{
        @include sideBarLink;
      }
    }
  }
}

/* Info */
.info{
  color: lighten($color-black, 10%);

  p{
    margin-top: 0;
  }

  a{
    color: lighten($color-black, 10%);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  &__text{
    flex: 1 0 220px;
    padding: 6px 20px 6px 6px;
    box-sizing: border-box;
    @media (max-width: 600px){
      text-align: center;
    }
  }
}

/* Contact */
.contact{
  font-family: 'Bebas Neue', Arial, sans-serif;
  font-size: 23px;
  font-weight: 600;
  line-height: 1;

  .sub{
    font-size: 19px;
  }

  .map{
    flex: 1 1 600px;
    filter: grayscale(100%);
  }

  .form-control{
    border: 0;
    background-color: #e6e7e8;
    color: $color-black;
    font-family: 'Bebas Neue', Arial, sans-serif;
    font-weight: 600;
    padding: 0.1em 0.4em 0.05em 0.4em;
    width: 100%;
    box-sizing: border-box;
    font-size: 0.85em;
    margin-bottom: 0.2em;
    resize: none;
    &::-webkit-input-placeholder {
      color: lighten($color-dark, 10%);
    }
    &::-moz-placeholder {
      color: lighten($color-dark, 10%);
    }
    &:-ms-input-placeholder {
      color: lighten($color-dark, 10%);
    }
    &:-moz-placeholder {
      color: lighten($color-dark, 10%);
    }

    &:focus{
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }
    }
  }

  .margin-sm{
    margin-bottom: 10px;
  }

  .btn{
    background-color: $color-black;
    border: 0;
    color: $color-white;
    font-size: 0.75em;
    position: relative;
    padding: 0.15em 0.8em 0.1em 0.8em;
    font-family: 'Bebas Neue', Arial, sans-serif;
    font-weight: 600;
    text-align: center;
    box-sizing: border-box;
    width: 67px;
    height: 27px;
    transition: .5s;

    &:hover {
      color: $color-dark;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.catalog{
  display: inline-flex;
  flex-direction: column;
  height: 104%;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  &__item{
    position: relative;
    width: 199px;
    height: 142px;
    background: $color-white;
    float: left;
    padding: 13px;
    margin-right: 14px;
    margin-bottom: 14px;
    cursor: pointer;

    &.new{
      &:before{
        content: 'New';
        position: absolute;
        z-index: 502;
        top: 13px;
        right: 13px;
        width: 50px;
        height: 23px;
        font-family: 'Bebas Neue', Arial, sans-serif;
        color: $color-white;
        background-color: $color-black;
        text-align: center;
        line-height: 1.4;
        font-size: 17px;
      }
    }
  }

  @media (max-width: 600px){
    width: 100%;
  }
}

.thumb{
  &:hover{
    .thumb__info {
      opacity: 1;
    }
  }

  @media (max-width: 600px){
    margin: 15px auto;
    width: 100%;
    height: auto;

    .thumb__img{
      width: calc(100% - 13px);
      height: auto;
    }

    img{
      width: 100%;
      height: auto;
    }
  }

  &__img{
    position: relative;
    z-index: 500;
    width: 199px;
    height: 124px;
    display: inline-block;
    vertical-align: top;

    a{
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
    }
    img{

    }
  }

  &__info {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    padding: 10px;
    background: rgba(0, 0, 0, .5);
    overflow: hidden;
    opacity: 0;
    transition: opacity .3s;
    font-family: "Times New Roman", Arial, sans-serif;
    font-style: italic;
    font-weight: 600;
  }

  &__title {
    margin-top: 7px;
    font-family: "Times New Roman", Arial, sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    color: $color-dark;

    a{
      color: $color-dark;
      text-decoration: none;

      &:hover {
        opacity: .8;
      }
    }
  }
}

/* Scroll arrow */
.scroll-arrow{
  display: none;
  .arrow{
    display: block;
    position: fixed;
    background-image: url(./../img/scroll_arrow_right.png);
    width: 45px;
    height: 45px;
    margin-top: 10px;
    transition: .3s;
  }

  .left{
    display: none;
    left: 10px;
    transform: rotate(180deg);

    &:hover{
      left: 5px;
    }
  }
  .right{
    right: 10px;

    &:hover{
      right: 5px;
    }
  }
}

/* Modal */
.modal{
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.modal-inner{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: 40px;
}

.article{
  display: flex;
  align-items: flex-end;
  z-index: 2;
  &__info{
    width: 230px;
    flex: 0 0 230px;
    padding-right: 20px;
    display: inline-block;
    vertical-align: bottom;
    color: $color-white;

    h1{
      font-family: 'Bebas Neue', Arial, sans-serif;
      color: $color-white;
      margin-top: 1em;
    }

    &__text{
      font-family: "Times New Roman", Arial, sans-serif;
      font-style: italic;
      font-size: 15px;

      a{
        color: #00eaff;
      }
    }
  }

  &__gallery{
    img{
      max-height: 479px;
      max-width: 676px;
      margin-right: 14px;
    }
  }
}

.close_btn{
  background-image: url(./../img/overlay_close.png);
  width: 37px;
  height: 37px;
  display: block;
  transition: .3s;

  &:hover{
    transform: rotate(-90deg);
  }
}

.hideScroller{
  overflow-x: hidden;
  position: fixed;
}

.overflow{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparentize($color-black, .15);
  z-index: 1;
}
@media (max-width: 600px){
  .overflow, .close_btn{
    display: none;
  }

  .modal-inner{
    padding: 13px;
    background-color: $color-white;
    box-sizing: border-box;
  }

  .article{
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__info {
      padding-right: 0;
      color: $color-dark;
      font-size: 15px;
      width: 100%;
      flex: 1 1 100%;
    }

    h1{
      font-size: 30px;
      font-weight: 600;
      margin: 0.5em 0;
      color: $color-black;
      line-height: 1em;
    }
  }

  .swiper-wrapper{
    flex-direction: column !important;
    img{
      margin-bottom: 14px;
    }
  }
}

@media (min-width: 601px) {
  .no-modal{
    display: flex;
    .overflow{
      display: none;
    }

    .article__info{
      color: $color-dark;

      h1{
        color: $color-black;
      }
    }

    .modal-inner{
      background-color: $color-white;
      margin-top: 5px;
      margin-left: 14px;
      padding-left: 0;
      height: calc(100% - 5px);
    }

    .left-menu{
      left: 0 !important;
    }
  }
}

.content-mask{
  @media (max-width: 600px){
    margin: 0 auto;
    width: 100%;
  }
}
/* map */
.map{
  ul{
    list-style-type: none;
    padding-left: 0;
  }

  &__nav{
    display: flex;

    &__item{
      flex: 1 1 auto;
      padding-right: 20px;

      a{
        line-height: 1.4;
        font-size: 13px;
      }
    }

    @media (max-width: 600px){
      flex-direction: column;
    }
  }

  .sub_cat{
    a{
      color: $color-dark;
    }
  }
}

/* Footer */
.footer{
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: 91%;
  padding-bottom: .5em;
  box-sizing: border-box;
  font-size: 11px;
  color: $color-dark;

  a{
    color: $color-dark;
    text-decoration: none;

    &:hover {
      opacity: .8;
    }
  }

  p{
    margin-bottom: 0;
  }

  @media (max-width: 600px){
    text-align: center;
    background-color: $color-light;
  }
}